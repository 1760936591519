import React, { useState, useEffect } from "react";
import Header from "../components/header";
import { CiHome } from "react-icons/ci";
import { IoIosArrowDown } from "react-icons/io";
import Line from "../assets/Line 3.svg";
import Searchicon from "../assets/search-normal.svg";
import PrimaryBtn, { TabBtn } from "../components/buttons";
import About1 from "../assets/about1.png";
import About2 from "../assets/about2.png";
// import House1 from "../assets/house1.png";
import Logo from "../components/logo";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import useAuthStore, { apiCall } from "../data/Stores/AuthStore";
import useSettingStore from "../data/Stores/settingsstore";
import { FaSearch } from "react-icons/fa";
import Filter from "../assets/filter-search.svg";
import { createMarkup } from "./pricing";
import useCategoryStore from "../data/Stores/categorystore";
import useListingStore from "../data/Stores/listingStore";
import { LoadMore, MainPaginate } from "../components/Pagination";

const Home = () => {
	return (
		<div>
			<motion.div exit={{ opacity: 0 }} duration={0.5}>
				<MainSection />
				<AboutSection />
				<ListingSection />
				{/* <ContactSection /> */}
				<Footer />
			</motion.div>
		</div>
	);
};

const MainSection = () => {
	const [tab, setTab] = useState(""),
		[selected, setSelected] = useState(null),
		[isType, setIsType] = useState(false),
		types = ["Apartment building", "villa", "shop"],
		{ data, getSetting } = useSettingStore(),
		navigate = useNavigate(),
		category = useCategoryStore(),
		{ isAuth } = useAuthStore(),
		handleType = t => {
			setSelected(t);
			setIsType(false);
		};

	useEffect(() => {
		apiCall("get", "/api/v1/settings", null, getSetting);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<section className="tw-w-full tw-overflow-x-hidden tw-overflow-y-visible tw-relative md:tw-py-20 md:tw-h-full lg:tw-min-h-screen lg:tw-h-screen tw-h-[60vh] tw-overflow-visible lg:tw-py-0 tw-py-20 herobg">
				<Header />
				<div className="tw-h-full tw-flex tw-items-center container">
					<div>
						<h1 className="lg:tw-text-5xl tw-text-[40px] tw-font-medium lg:tw-leading-[72px] tw-leading-[54px] tw-text-white satoshi">
							{data?.homeSubTitle || "Find your"}
							<br />
							<span className="lg:tw-text-7xl bolder tw-text-5xl tw-text-white">
								{data?.homeTitle || "Perfect Home"}
							</span>
						</h1>
						<button
							onClick={() => navigate(isAuth ? "/trivia" : "/")}
							className="tw-mt- tw-h-12 tw-w-32 tw-bg-white tw-rounded-2xl tw-border-0.5 tw-border-[#0A1C2DB2] tw-text-base tw-text-scblue tw-font-medium satoshi tw-hidden">
							{data?.homeButton || "Play Trivia"}
						</button>
					</div>
				</div>
				<div className="lg:tw-absolute tw-hidden lg:tw-block md:tw-mt-20 tw-ml-16 lg:tw-ml-0 tw-left-24 tw-mt-20 lg:tw-mt-0 tw-z-50 lg:tw-w-3/4 tw-bottom-2">
					<div className="tw-flex">
						{category?.data?.docs?.map((it, i) => (
							<button
								key={i}
								onClick={() => setTab(it?._id)}
								className={`tw-h-12 tw-w-24 tw-rounded-sm ${
									tab === it?._id
										? "tw-bg-primary tw-text-white"
										: "tw-bg-white tw-text-scblue"
								} tw-text-lg tw-font-medium satoshi`}>
								{it?.homePageTitle}
							</button>
						))}
					</div>
					<div className="lg:tw-h-20 tw-space-y-5 tw-py-4 lg:tw-space-y-0 tw-bg-white tw-w-3/4 tw-px-6 tw-flex tw-flex-col lg:tw-flex-row tw-justify-between lg:tw-items-center">
						<div
							onClick={() => setIsType(!isType)}
							className="tw-flex tw-relative tw-gap-3 tw-cursor-pointer tw-h-full tw-items-center">
							<CiHome className="tw-text-scblue tw-mt-1" />
							<div>
								<strong className="tw-text-base tw-capitalize tw-font-normal satoshi tw-text-scblue">
									{selected === null ? "Property type" : selected}
								</strong>
							</div>
							<IoIosArrowDown className="tw-text-scblue tw-mt-1" />
							{isType && (
								<div className="tw-absolute tw-z-[1000] tw-top-16 tw-w-64 tw-p-6 tw-bg-white tw-rounded-lg">
									<div className="tw-space-y-3">
										{types?.map((type, i) => (
											<div key={i}>
												<small
													onClick={() => handleType(type)}
													className="tw-text-scblue tw-capitalize tw-cursor-pointer tw-font-normal tw-text-base satoshi">
													{type}
												</small>
											</div>
										))}
									</div>
								</div>
							)}
						</div>

						<div className="tw-flex">
							<img src={Line} alt="" className="" />
							<div className="tw-relative tw-w-96 tw-h-10 tw-ml-10 tw-border-none">
								<img
									src={Searchicon}
									alt=""
									className="tw-absolute tw-top-2 tw-left-3"
								/>
								<input
									type="text"
									placeholder="Search by location or Property ID"
									className="tw-h-full tw-w-full tw-pl-10 tw-border-none focus:tw-border-none tw-text-base tw-font-normal satoshi tw-text-scblue"
								/>
							</div>
						</div>
						<div>
							<PrimaryBtn
								bg={"#005BAD"}
								color={"white"}
								text={"Search"}
								onClick={e => {
									e?.preventDefault();
									if (!tab) return;
									navigate("/properties", { state: tab });
								}}
							/>
						</div>
					</div>
				</div>
			</section>
			<div className="tw-mt-16 tw-block lg:tw-hidden tw-py-12 tw-bg-[#F9FBFF] tw-w-full">
				<div className="container">
					<div className="tw-flex tw-gap-5">
						{category?.data?.docs?.map((it, i) => (
							<button
								key={i}
								onClick={() => {
									setTab(it?._id);
									navigate("/properties", { state: it?._id });
								}}
								className={`tw-h-12 tw-rounded-xl tw-w-24 ${
									tab === it?._id
										? "tw-bg-primary tw-text-white"
										: "tw-bg-white tw-text-scblue"
								} tw-text-lg tw-font-medium satoshi`}>
								{it?.homePageTitle}
							</button>
						))}
					</div>
					<div className="tw-h-14 tw-w-full tw-mt-4 tw-relative">
						<FaSearch size={20} className="tw-absolute tw-left-4 tw-top-4" />
						<input
							type="text"
							placeholder="Search by location or property ID"
							className="tw-w-full tw-pl-12 tw-bg-white tw-h-full tw-border tw-border-[#1C466C33] tw-rounded-xl"
						/>
						<div className="tw-absolute tw-top-0 tw-right-0 tw-py-2 tw-w-16 tw-h-full">
							<div className="tw-h-full tw-border-l tw-border-[#1C466C33] tw-flex tw-items-center tw-justify-center">
								<img src={Filter} alt="" className="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const AboutSection = () => {
	let { data } = useSettingStore();

	return (
		<section className="tw-w-full tw-bg-white tw-pb-32 tw-pt-20">
			<div className="container tw-grid lg:tw-grid-cols-2 tw-items-center tw-gap-10">
				<div className="tw-relative tw-w-full">
					<img src={About1} alt="about" className="tw-w-11/12" />
					<div
						style={{
							boxShadow: "10px 8px 25.100000381469727px 0px #00000026",
						}}
						className="tw-absolute -tw-right-0 -tw-bottom-20 tw-rounded-md md:tw-w-64 tw-w-52 tw-bg-white">
						<img src={About2} alt="about" className="" />
						<div className="lg:tw-p-4 tw-p-3">
							<strong className="tw-text-base tw-font-bold satoshi tw-text-scblue">
								{data?.section1SubTitle3 || `Lorem ipsum`}
							</strong>
							<p className="tw-text-sm tw-font-normal tw-text-scblue satoshi">
								{data?.section1SubTitle2 ||
									`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore`}{" "}
							</p>
						</div>
					</div>
				</div>
				<div className="tw-mt-20 lg:tw-mt-0">
					<h2 className="tw-text-4xl tw-font-bold satoshi tw-text-scblue">
						{data?.section1Title || `About Us`}
					</h2>
					<p className="tw-text-scblue tw-font-normal satoshi tw-text-base">
						{data?.section1SubTitle ||
							`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
						eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
						ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
						aliquip ex ea commodo consequat. Duis aute irure dolor in
						reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
						pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
						culpa qui officia deserunt mollit anim id est laborumLorem ipsum
						dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
						incididunt ut labore et dolore magna aliqua. Ut enim ad minim
						veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
						ea commodo consequat. Duis aute irure dolor in reprehenderit in
						voluptate velit esse cillum dolore eu fugiat nulla pariatur.
						Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
						officia deserunt mollit anim id est laborum`}
					</p>
				</div>
			</div>
		</section>
	);
};

const ListingSection = () => {
	const [tab, setTab] = useState("all");
	// const Arr = [
	// 	{
	// 		name: "House 001",
	// 		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ",
	// 		features: [
	// 			{ main: "1 bedroom", sub: "1 bathroom" },
	// 			{ main: "1 parking", sub: "1 kitchen" },
	// 		],
	// 	},
	// 	{
	// 		name: "House 001",
	// 		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ",
	// 		features: [
	// 			{ main: "1 bedroom", sub: "1 bathroom" },
	// 			{ main: "1 parking", sub: "1 kitchen" },
	// 		],
	// 	},
	// 	{
	// 		name: "House 001",
	// 		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ",
	// 		features: [
	// 			{ main: "1 bedroom", sub: "1 bathroom" },
	// 			{ main: "1 parking", sub: "1 kitchen" },
	// 		],
	// 	},
	// 	{
	// 		name: "House 001",
	// 		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ",
	// 		features: [
	// 			{ main: "1 bedroom", sub: "1 bathroom" },
	// 			{ main: "1 parking", sub: "1 kitchen" },
	// 		],
	// 	},
	// 	{
	// 		name: "House 001",
	// 		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ",
	// 		features: [
	// 			{ main: "1 bedroom", sub: "1 bathroom" },
	// 			{ main: "1 parking", sub: "1 kitchen" },
	// 		],
	// 	},
	// 	{
	// 		name: "House 001",
	// 		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ",
	// 		features: [
	// 			{ main: "1 bedroom", sub: "1 bathroom" },
	// 			{ main: "1 parking", sub: "1 kitchen" },
	// 		],
	// 	},
	// ];

	let { data } = useSettingStore(),
		category = useCategoryStore(),
		[loading, setLoading] = useState(null),
		[datum, setDatum] = useState(null),
		listing = useListingStore();

	useEffect(() => {
		apiCall("get", "/api/v1/category", null, category?.getCategory);
		apiCall("get", "/api/v1/listing", null, listing?.getListing);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (tab && tab !== "all") {
			let getFunc = async () => {
				let { response } = await apiCall(
					"get",
					`/api/v1/listing?category=${tab}`
				);
				listing?.getListing({
					...response,
					adder: category?.data?.docs?.find(it => it?._id === tab)?.title,
				});
			};
			getFunc();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tab]);

	useEffect(() => {
		if (tab === "all") setDatum(listing?.data);
		else {
			let finder = category?.data?.docs?.find(it => it?._id === tab);
			if (finder && listing?.[finder?.title]) {
				setDatum(listing?.[finder?.title]);
			} else {
				setDatum(null);
			}
		}
	}, [category?.data?.docs, listing, tab]);

	const [range] = useState(10),
		[page, setPage] = useState(1),
		[itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
		setPage(1 + event?.selected);
	};

	let handleLoadMore = async () => {
		setLoading("loadmore");

		let { response } = await apiCall(
			"get",
			`/api/v1/listing?limit=${datum?.limit * datum?.nextPage}${
				tab && tab !== "all" ? `&category=${tab}` : ""
			}`
		);
		listing?.getListing(
			tab && tab !== "all"
				? {
						...response,
						adder: category?.data?.docs?.find(it => it?._id === tab)?.title,
				  }
				: response
		);
		setLoading(false);
	};

	return (
		<section className="tw-w-full tw-bg-[#F9FBFF] tw-mt-16 tw-py-20">
			<div className="container">
				<div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between lg:tw-items-center">
					<h3 className="tw-text-4xl tw-text-scblue tw-font-bold satoshi">
						{data?.section2Title || (
							<>
								Discover Our <br /> Featured Listing
							</>
						)}
					</h3>
					<p className="smallText tw-max-w-xl">
						{data?.section2SubTitle ||
							`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
						eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
						ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
						aliquip ex ea commodo consequat. Duis aute irure dolor in
						reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
						pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
						culpa qui`}
					</p>
				</div>
				<div className="tw-mt-20 tw-flex tw-gap-6 tw-items-center tw-border-b-0.5 tw-border-b-scblue tw-pb-8">
					<TabBtn
						onClick={() => setTab("all")}
						text={"All"}
						css={
							tab === "all"
								? "tw-border-none"
								: "tw-border-0.5 tw-border-[#0A1C2DB2]"
						}
						bg={tab === "all" ? "#1C466C" : "white"}
						color={tab === "all" ? "white" : "#1C466C"}
					/>
					{category?.data?.docs?.map((item, i) => (
						<TabBtn
							key={i}
							onClick={() => setTab(item?._id)}
							text={item?.propertyPageTitle}
							css={
								tab === item?._id
									? "tw-border-none"
									: "tw-border-0.5 tw-border-[#0A1C2DB2]"
							}
							bg={tab === item?._id ? "#1C466C" : "white"}
							color={tab === item?._id ? "white" : "#1C466C"}
						/>
					))}
				</div>
				<div className="tw-mt-16 tw-grid lg:tw-grid-cols-3 md:tw-grid-cols-2 tw-gap-12">
					{currentItems?.slice(0, 3)?.map((it, i) => (
						<div
							key={i}
							style={{
								boxShadow: "13px 25px 55.900001525878906px 0px #0000001A",
							}}
							className="tw-h-[550px] tw-bg-white tw-relative tw-rounded tw-p-5">
							{it?.image?.url && (
								<img src={it?.image?.url} alt="house" className="tw-w-full" />
							)}
							<div className="tw-mt-6">
								<h3 className="tw-text-2xl bolder tw-text-scblue">
									{it?.title}
								</h3>
								<div className="tw-overflow-hidden">
									<div
										className="tw-text-xs tw-font-normal tw-leading-normal satoshi tw-text-scblue truncate"
										dangerouslySetInnerHTML={createMarkup(it?.content)}
									/>
								</div>
								<div className="tw-space-y-3 tw-w-full">
									<div className="tw-w-full tw-flex tw-justify-between tw-items-center">
										<div>
											<small className="midText tw-capitalize">
												{it?.noOfBedroom ? (
													<>{it?.noOfBedroom} Bedroom</>
												) : null}
											</small>
										</div>
										<div>
											<small className="midText tw-capitalize">
												{it?.noOfBedroom ? (
													<>{it?.noOfBedroom} Bathroom</>
												) : null}
											</small>
										</div>
									</div>
									<div className="tw-w-full tw-flex tw-justify-between tw-items-center">
										<div>
											<small className="midText tw-capitalize">
												{it?.noOfParking ? (
													<>{it?.noOfParking} Parking</>
												) : null}
											</small>
										</div>
										<div>
											<small className="midText tw-capitalize">
												{it?.noOfKitchen ? (
													<>{it?.noOfKitchen} Kitchen</>
												) : null}
											</small>
										</div>
									</div>
								</div>
							</div>
							<div className="tw-absolute tw-bottom-4 tw-w-full  tw-left-0 tw-right-0 tw-flex tw-justify-center tw-items-center tw-px-4">
								<button
									style={{
										boxShadow: "0px 6px 15.5px 0px #00000040",
									}}
									onClick={() => {
										apiCall("patch", `/api/v1/listing?listing=${it?._id}`);
										window.open(it?.linkedURL, "_blank");
									}}
									className="tw-h-12 tw-w-full tw-text-base tw-font-bold tw-text-white satoshi tw-rounded-xl tw-bg-scblue">
									Read More
								</button>
							</div>
						</div>
					))}
				</div>
				<div className="tw-mt-4 tw-flex tw-justify-center">
					<LoadMore
						next={page === pageCount && datum?.hasNextPage}
						loading={loading === "loadmore"}
						handleLoadMore={handleLoadMore}
					/>
				</div>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
			</div>
		</section>
	);
};

export const ContactSection = () => {
	return (
		<section className="tw-mt-20 tw-w-full tw-bg-[#F0F2F5] tw-flex tw-justify-center tw-items-center tw-h-56">
			<div>
				<h4 className="tw-text-xm tw-text-center tw-font-bold tw-text-black satoshi">
					Contact Us
				</h4>
				<div className="tw-mt-4 tw-mx-auto">
					<div>
						<small className="smallText tw-text-black">Email Address</small>
					</div>
					<div className="tw-mt-2 tw-w-96 tw-relative tw-h-14">
						<input
							type="email"
							name=""
							className="tw-w-full tw-h-full tw-bg-white tw-border-0.5 twrounded tw-border-[#0A1C2D66]"
							id=""
						/>
						<div className="tw-absolute tw-right-0 tw-top-0">
							<PrimaryBtn
								bg={"#1C466C"}
								color={"white"}
								height={"tw-h-14"}
								text={"Send"}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export const Footer = () => {
	const navigate = useNavigate(),
		{ isAuth } = useAuthStore();
	const links1 = [
		{ name: "home", url: "/", show: true },
		{ name: "properties", url: "/properties", show: true },
		{ name: "Pages", url: "/pages", show: false },
		{ name: "contact us", url: "/contact", show: true },
	];
	const links2 = [
		{
			name: "leaderboard",
			url: isAuth ? "/leaderboard" : "/login",
			show: false,
		},
		{ name: "trivia", url: isAuth ? "/trivia" : "/login", show: false },
		{ name: "contact us", url: "/contact", show: false },
	];
	const links3 = [
		{ name: "terms & condition", url: "/terms", show: true },
		{ name: "policy", url: "/policy", show: true },
		{ name: "pricing", url: "/pricing", show: false },
		{ name: "knowledge check", url: "/contact", show: true },
	];
	const links4 = [
			{ name: "sign up", url: isAuth ? "/" : "/register", show: false },
			{ name: "login", url: isAuth ? "/" : "/login", show: false },
		],
		{ data } = useSettingStore();
	return (
		<footer className="tw-w-full tw-bg-[#1C466C] tw-py-8">
			<div className="container tw-flex tw-flex-col lg:tw-flex-row tw-justify-between">
				<div className="tw-flex tw-items-center tw-gap-5">
					<Logo />
					<div className="tw-hidden lg:tw-block">
						<h6 className="tw-text-white tw-text-lg tw-font-medium satoshi">
							{data?.footerTitle || ``}
						</h6>
						<p className="tw-text-sm tw-font-normal lg:tw-w-96 tw-text-white satoshi">
							{data?.footerSubTitle || ``}
						</p>
					</div>
				</div>
				<div className="tw-grid lg:tw-grid-cols-4 tw-grid-cols-2 tw-gap-5 tw-gap-y-6">
					<div className="tw-space-y-2">
						{links1
							?.filter(it => it?.show)
							?.map(it => (
								<div
									onClick={() =>
										navigate(it?.url, {
											state: it?.url === "/properties" ? "all" : "",
										})
									}
									key={it?.url}
									className="">
									<strong className="tw-text-sm tw-font-medium satoshi tw-capitalize tw-text-white tw-cursor-pointer">
										{it?.name}
									</strong>
								</div>
							))}
					</div>
					<div className="tw-space-y-2 tw-hidden">
						{links2
							?.filter(it => it?.show)
							?.map(it => (
								<div
									onClick={() => navigate(it?.url)}
									key={it?.url}
									className="">
									<strong className="tw-text-sm tw-font-medium satoshi tw-capitalize tw-text-white tw-cursor-pointer">
										{it?.name}
									</strong>
								</div>
							))}
					</div>
					<div className="tw-space-y-2">
						{links3
							?.filter(it => it?.show)
							?.map(it => (
								<div
									onClick={() => navigate(it?.url)}
									key={it?.url}
									className="">
									<strong className="tw-text-sm tw-font-medium satoshi tw-capitalize tw-text-white tw-cursor-pointer">
										{it?.name}
									</strong>
								</div>
							))}
					</div>
					<div className="tw-space-y-2">
						{links4
							?.filter(it => it?.show)
							?.map(it => (
								<div
									onClick={() => navigate(it?.url)}
									key={it?.url}
									className="">
									<strong className="tw-text-sm tw-font-medium satoshi tw-capitalize tw-text-white tw-cursor-pointer">
										{it?.name}
									</strong>
								</div>
							))}
					</div>
				</div>
				<div className="tw-block tw-mt-8 lg:tw-hidden">
					<h6 className="tw-text-white tw-text-lg tw-font-medium satoshi">
						{data?.footerTitle || ``}
					</h6>
					<p className="tw-text-sm tw-font-normal lg:tw-w-96 tw-text-white satoshi">
						{data?.footerSubTitle || ``}
					</p>
				</div>
			</div>
		</footer>
	);
};

export default Home;
